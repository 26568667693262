import React, { useState } from "react";
import Dropzone from "react-dropzone";

function BgChanger() {
  const [pageImage, setPageImage] = useState(null);

  const handleDrop = (acceptedFiles) => {
    setPageImage(URL.createObjectURL(acceptedFiles[0]));
  };

  return (
    <div>
      {!pageImage && (
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <div style={{ width: "100%" }} {...getRootProps()}>
              <input {...getInputProps()} />
              <p
                style={{
                  backgroundColor: "#80808060",
                  border: "1px solid",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 500,
                }}
              >
                Drag and drop a screenshot image file here, or click to select
                file
              </p>
            </div>
          )}
        </Dropzone>
      )}

      {pageImage && (
        <img src={pageImage} alt="Page" style={{ width: "100%" }} />
      )}
    </div>
  );
}
export default BgChanger;
