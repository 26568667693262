import { Magny } from "magny";
import "magny/style.css";
import React from "react";
import BgChanger from "./Components/BgChanger";

function App() {
  return (
    <div
      style={{
        maxWidth: "1140px",
        margin: "auto",
      }}
    >
      <Magny projectId="58f1168f-b777-4682-a778-ee986e9e27af" />
      <BgChanger />
    </div>
  );
}
export default App;
